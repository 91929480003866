// change the theme
$theme-colors: (
  'primary': #70009c,
  'secondary': #ff8c2e,
);

$body-bg: #fff;
$body-color: #111;

// Import Bootstrap and its default variables
@import 'node_modules/bootstrap/scss/bootstrap';

// Ant Design
@import '~antd/dist/antd.compact.css';

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&family=Roboto:wght@400;500&family=Cabin:wght@689;700&family=Arimo:wght@600&display=swap');

.ant-menu-item .logo {
  width: 107.11px;
  height: 32px;
}

.ant-btn-primary {
  background-color: #70009c;
  color: #fff;
}
.site-layout .site-layout-background {
  background: #fff;
}
